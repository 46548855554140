import React, { Component } from 'react';
import { BREAKPOINTS } from 'constants';
import VehicleDetails from 'components/VehicleDetailsCard';
import VehicleFilterToggle from 'components/VehicleFilterToggle';
import NoResultsVehicleGrid from 'components/NoResultsVehicleGrid';
import { propTypes, defaultProps } from './VehicleGridPropTypes';

class VehicleGrid extends Component {
  shouldComponentUpdate = (nP) => !(nP.isFilterExpanded && nP.breakpoint === BREAKPOINTS.SMALL);

  // prevent commas between seo content entries
  joinedSeoContent = (seoContent) => (Array.isArray(seoContent) ? seoContent.join('') : seoContent);

  render = () => {
    const { vehicles, categories, isVehicleLandingPage, breakpoint, numFiltersSelected, clearFilters } = this.props;

    //  No vehicle results and filter has been applied
    if (numFiltersSelected > 1 && vehicles.length === 0) {
      return (
        <>
          {breakpoint === BREAKPOINTS.MEDIUM && (
            <VehicleFilterToggle {...this.props} onClick={this.props.handleToggleFilter} />
          )}
          <NoResultsVehicleGrid clearFilters={clearFilters} />
        </>
      );
    }
    return (
      <>
        {breakpoint === BREAKPOINTS.MEDIUM && (
          <VehicleFilterToggle {...this.props} onClick={this.props.handleToggleFilter} />
        )}
        {categories.map((category) => {
          const filteredVehicles = vehicles.filter((vehicle) => vehicle.categoryCode === category.categoryCode);
          return filteredVehicles.length < 1 ? null : (
            <div className='vehicle-grid__category' key={category.categoryCode}>
              {isVehicleLandingPage && (
                <h5 className='vehicle-grid__category__title'>
                  <a className='link--arrow' href={category.url}>{`${category.name} (${filteredVehicles.length})`}</a>
                </h5>
              )}
              {category.seoContent && (
                <div
                  className='vehicle-grid__seo-content'
                  dangerouslySetInnerHTML={{ __html: this.joinedSeoContent(category.seoContent) }}
                />
              )}
              <ul className='vehicle-grid__vehicles'>
                {filteredVehicles.map((car) => (
                  <li key={car.code}>
                    <VehicleDetails {...car} />
                  </li>
                ))}
              </ul>
            </div>
          );
        })}
      </>
    );
  };
}
VehicleGrid.defaultProps = defaultProps;
VehicleGrid.propTypes = propTypes;

export default VehicleGrid;
